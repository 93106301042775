/**
BRGIN RESET CSS
 */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, font, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}
body {
  line-height: 1;
}
ol, ul {
  list-style: none;
}
blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}
/* remember to define focus styles! */
:focus {
  outline: 0;
}
/* remember to highlight inserts somehow! */
ins {
  text-decoration: none;
}
del {
  text-decoration: line-through;
}
/* tables still need 'cellspacing="0"' in the markup */
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/**
END RESET CSS
 */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#wrapper-content{
  max-width: 1200px;
  margin: 100px auto;
}

#wrapper-content h1 {
  text-align: center;
  font-size: 30px;
  margin-bottom: 30px;
}
#wrapper-content h3 {
  text-align: center;
  font-size: 20px;
}
#container-form {
  max-width: 600px;
  margin:30px auto;
}
.d-bgcolor{
  /*background-color: #a52810!important;*/
  background-color: #BC2A57!important;
}
.d-bordercolor{
  /*border-color: #a52810!important;*/
  border-color: #BC2A57!important;
}
/*#wrapper-content form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}
.bgcolor{}
.box-new-sign{

}*/
.button-center{
  text-align: center!important;
}
.box-new-sign div{
  background-color: #ececec;
  border-radius: 10px;
  padding:10px;
  margin: 10px;
}
.icons-header{
  font-size: 30px;
  margin-top: 5px;
}
.welcome-message{
  float: right;
  margin-left: 30px;
}
.box-new-sign > div{
  min-height: 350px;
}
.box-file-upload .list-upload-files{
  text-align: left;
}
.button-remove-file{
  font-size: 11px!important;
  padding: 2px!important;
  float: right!important;
}
.is-active{
  display: block;
}
.not-active{
  display: none;
}
#basic-navbar-nav .only-link-menu-sign{
  padding-top: 19px;
}
#files-list-download a,
.cursor{
  /*color:#a52810;*/
  color:#BC2A57;
  cursor:pointer;
  font-weight: bold;
}
.bold-magenta{
  color:#BC2A57;
  font-weight: bold;
}
.list-circle{
  list-style-type: circle;
}
.navbar-sign{
  display: flex!important;
  flex-direction: row;
  justify-content: space-around!important;
}
.navbar-sign .menu-sign{
  justify-content: flex-end;
}
.table tr{
  line-height: var(--bs-body-line-height);
}
#results-search{
  margin-bottom: 50px;
}
.fontRedUnderline {
  /*color:#a52810;*/
  color:#BC2A57;
  text-decoration: underline;
}
.margin-bottom-30{
  margin-bottom: 30px;
}
.padding-left-30{
  padding-left: 30px!important;
}
.list-upload-files{
  margin-top:30px;
}
.list-upload-files li{
  height: 30px;
  position: relative;
}
.list-upload-files li button{
  position: absolute;
  right: 0;
  top:0;
}
.truncate {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 25%;
}
.login-page h1 {
  margin-top:20px;
}
.login-page .btn{
  /*background-color: #a52810!important;*/
  background-color: #BC2A57!important;
}
.operation-details h1 {
  margin-bottom:30px;
}
.red-message{
  /*color:#a52810;*/
  color:#BC2A57;
}
.black-message{
  color:#333;
}
.wrapper-content{
  max-width: 1200px;
  margin: 0 auto;
}